import IbatApi from "../../utils/IbatApi";
import React, {Fragment, useEffect, useState} from "react";
import {capitalize, IconButton} from "@mui/material";
import EditIcon from "../../images/pen-edit-icon.png"
import constants, {text} from "../util/constants";
import Select from "react-select";
import {useSnackbar} from "notistack";
import SearchImg from "../../images/search-icon.png";
import {v4} from "uuid";
import {validateEmail} from "../../utils/Utils";

const api=new IbatApi()

/*export function  UserEditor({id, username, userId, companyId, enabled, authorities, accountNonExpired, accountNonLocked, credentialsNonExpired}){
    const {enqueueSnackbar} = useSnackbar();
    const [state,setState]=useState({
            id:id,
            username:username,
            password:"",
            userId:userId,
            companyId:companyId,
            enabled:enabled,
            authorities:authorities,
            accountNonExpired:accountNonExpired,
            accountNonLocked:accountNonLocked,
            credentialsNonExpired:credentialsNonExpired
        }
    )
    useEffect(() => {
      setState()
    }, [])
    const [selectedAuth,setSelectedAuth]=useState(authorities?.map(a=>({label:a,value:a})))



    //     function handleRole(e) {
    //     setState({...state,authorities:[{authority:e.target.value}] })
    // }

    function handlePassword(e) {
        setState({...state,password:e.target.value})
    }

    function update() {
        api.updateUser(state).then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function deleteUser() {
        api.deleteUser(id).then(r => enqueueSnackbar(text.SNACKBAR_ERASED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleRole(ops) {
        setSelectedAuth(ops)
        setState({...state,authorities:ops.map(op=>(op.value))})
    }
    return <div>
        <input type={"text"} value={state.username} disabled/>
        <input type={"password"} value={state.password} onChange={handlePassword}/>
        <Select
            isMulti
            onChange={handleRole}
            placeholder={text.ENTER_ROLES}
            options={[{label:constants.TEAM,value:constants.TEAM},
                {label:constants.RT,value:constants.RT},
                {label:constants.GESTIONNAIRE,value:constants.GESTIONNAIRE},
                {label:constants.COMPTA,value:constants.COMPTA},
                {label:constants.ADMIN,value:constants.ADMIN},
                {label:constants.PERMISSION_AM,value:constants.PERMISSION_AM},
                {label:constants.PERMISSION_BILL,value:constants.PERMISSION_BILL},
                {label:constants.PERMISSION_CRE,value:constants.PERMISSION_CRE},
                {label:constants.PERMISSION_TRE,value:constants.PERMISSION_TRE},
                {label:constants.PERMISSION_EC,value:constants.PERMISSION_EC},
                {label:constants.PERMISSION_PAYMENT,value:constants.PERMISSION_PAYMENT},
                {label:constants.PERMISSION_PM,value:constants.PERMISSION_PM},
            ]}
            className={"select-fr.tegeo.ibat.core.operation"}
            value={selectedAuth}
        />
            <div className={"userOptions"}> <label htmlFor={state.id}>{text.ACTIVATED}</label><input id={state.id} type={"checkbox"} checked={state.enabled} disabled />
            <IconButton onClick={deleteUser}> <img alt={"supprimer"} src={BinIcon}/></IconButton>
            <IconButton onClick={update} className={"action-btn validation"}>{text.SAVE}</IconButton>
            </div>
    </div>
}*/
export function  UserEditorMod({user, allUser, updateUserList, updateUserToMod}){
    const {enqueueSnackbar} = useSnackbar();
    const [state,setState]=useState(reInitState())
    const [selectedAuth,setSelectedAuth]=useState(user?.authorities?.map(a=>({label:a,value:a})))
    const [selectedSecurityNumbers,setSecurityNumbers]=useState(user?.securityNumbers?.map(s=>({label:s,value:s})))
    const [optionRoles]=useState([
      {label:constants.TEAM_LABEL,value:constants.TEAM},
      {label:constants.RT_LABEL,value:constants.RT},
      {label:constants.GESTIONNAIRE_LABEL,value:constants.GESTIONNAIRE},
      {label:constants.COMPTA_LABEL,value:constants.COMPTA},
      {label:constants.ADMIN_LABEL,value:constants.ADMIN},
      {label:constants.PERMISSION_AM_LABEL,value:constants.PERMISSION_AM},
      {label:constants.PERMISSION_BILL_LABEL,value:constants.PERMISSION_BILL},
      {label:constants.PERMISSION_CRE_LABEL,value:constants.PERMISSION_CRE},
      {label:constants.PERMISSION_TRE_LABEL,value:constants.PERMISSION_TRE},
      {label:constants.PERMISSION_EC_LABEL,value:constants.PERMISSION_EC},
      {label:constants.PERMISSION_PAYMENT_LABEL,value:constants.PERMISSION_PAYMENT},
      {label:constants.PERMISSION_PM_LABEL,value:constants.PERMISSION_PM},
      {label:constants.PERMISSION_HR_LABEL,value:constants.HR},
    ])
    const [jobTypeList, setJobTypeList] = useState();
    const [createRunning,setCreateRunning]=useState(false)
    const [allUserNumber, setAllUserNumber] = useState()
    useEffect(() => {
      setState(user)

      api.getJobTypesOfCompany().then(resp => {
        setJobTypeList(
          resp?.map(jt => {
              return {
                label: jt.name,
                value: jt.id
              }
            }
          ))
      })

      setSelectedAuth(optionRoles?.filter(role=> user?.authorities?.includes(role.value))?.map(r=>({label:r.label, value:r.value})))
      if (allUser){
        setAllUserNumber(allUser?.map(u=>{
          if (u.username && u.phoneNumber){
            return {
              label:u.username,
              value:u.phoneNumber
            }
          }
          else {
            return null
          }
        }).filter(u=>u?.value!==undefined))
        setSecurityNumbers(allUserNumber?.filter(numbers=> user?.securityNumbers?.includes(numbers.value))?.map(n=>({label:n.label, value:n.value})))
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, allUser])

    function reInitState() {
        return  {
            id:user?.id,
            username:user?.username,
            password:"",
            userId:user?.userId,
            companyId:user?.companyId,
            enabled:user?.enabled,
            authorities:user?.authorities,
            accountNonExpired:user?.accountNonExpired,
            accountNonLocked:user?.accountNonLocked,
            credentialsNonExpired:user?.credentialsNonExpired,
            geoClock: user?.geoClock,
            securityService: user?.securityService,
            phoneNumber: user?.phoneNumber,
            securityNumbers: user?.securityNumbers
        }
    }

    function handlePassword(e) {
        setState({...state,password:e.target.value})
    }
    function handleUsername(e) {
        setState({...state,username:e.target.value})
    }
    function handleEmail(e) {
        setState({...state,email:e.target.value})
    }
    function handlePhoneNumber(e) {
        setState({...state,phoneNumber:e.target.value})
    }
    function handleGeoClock(e) {
        let geoClock = e.target.checked
        let securityService = !e.target.checked ? false : state.securityService
        setState({...state,geoClock:geoClock, securityService: securityService})
    }
    function handleSecurityService(e) {
        setState({...state,securityService:e.target.checked})
    }
    function handleJobType(jt) {
    setState({...state,jobType:jt.map(n=>(n.value))})
  }
    function handleDelete() {
        let tmp = {...state,deleted: !state.deleted}
        setState(tmp)
        update(tmp)
      }
    function createUser() {
        if(!state.username){
            enqueueSnackbar("Aucun nom d'utilisateur",{variant:"error"})
        }
        if(!validateEmail(state.email)) {
            return
        }
        if(!state.password){
            enqueueSnackbar("Aucun mot de passe",{variant:"error"})
        }
        if(!state.authorities){
            enqueueSnackbar("Aucun rôle",{variant:"error"})
        }
        if (state.username && state.password && state.authorities && !createRunning){
            setCreateRunning(true)
            api.creatUser(state)
                .then(r => {
                    setState(reInitState())
                    updateUserList()
                    setCreateRunning(false)
                    enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"})
                })
                .catch(e=>{
                    setCreateRunning(false)
                    let msg = e?.response?.data && e?.response?.data?.length>0 ? e?.response?.data : text.SNACKBAR_ERROR
                    enqueueSnackbar(msg, {variant: 'error'})
                })
        }
    }
    function update(user) {
        updateUserToMod(user??state)
        api.updateUser(user ?? state)
            .then(r => {
                updateUserList()
                enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"})
            })
            .catch((e) => {
                let msg = e?.response?.data && e?.response?.data?.length>0 ? e?.response?.data : text.SNACKBAR_ERROR
                enqueueSnackbar(msg, {variant: 'error'})
            })
    }

   /* function deleteUser() {
        !user.authorities.includes(constants.ADMIN) &&
        api.deleteUser(user.id).then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
        api.deleteUser(user.id)
            .then(r => {
                updateUserList()
                enqueueSnackbar(text.SNACKBAR_ERASED,{variant:"success"})
            })
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }*/

    function handleRole(ops) {
        setSelectedAuth(ops)
        setState({...state,authorities:ops.map(op=>(op.value))})
    }
    function handleSecurityNumber(num) {
      setSecurityNumbers(num)
      setState({...state,securityNumbers:num.map(n=>(n.value))})
    }


    return <div className={"secondary-section flex-column w100"}>
        <input className={"large w100"} placeholder={text.ENTER_USERNAME} type={"text"} value={state?.username?? ""} onChange={handleUsername} required={true} disabled={state?.id ?? false}/>
        <input className={"large w100"} placeholder={text.TOOLTIP_ENTER_USER_EMAIL} type={"text"} value={state?.email?? ""} onChange={handleEmail} required={true} disabled={state?.id ?? false}/>
        <input className={"large w100"} placeholder={text.PASSWORD} type={"password"} autoComplete={"new-password"} value={state?.password ?? ""} onChange={handlePassword} required={true}/>
        <input className={"large w100"} id={"phoneNumber"+state?.id} type={"text"} value={state?.phoneNumber ?? ""} placeholder={"0612345789"} onChange={handlePhoneNumber}/>
        <Select
            className={"large w100"}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            isMulti
            onChange={handleRole}
            placeholder={text.ENTER_ROLES}
            options={optionRoles}
            value={selectedAuth}
        />
      {selectedAuth?.filter(auth=>auth.value === constants.TEAM).length>0 &&
        <Select
            className={"large w100"}
          styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
          menuPortalTarget={document.body}
          isMulti
          onChange={handleJobType}
          placeholder={"Corps de métier concernés"}
          options={jobTypeList}
          value={jobTypeList?.filter(jt=> state?.jobType?.includes(jt.value))}
        />
      }
      {
          selectedAuth?.filter(auth=>auth.value === constants.TEAM).length>0 &&
          <div className={"large w100 userOptions"}>

        <div className={"large w100 security-box"}>
        <input id={"geoclock" + state?.id} type={"checkbox"} checked={state?.geoClock ?? false} onChange={handleGeoClock}/>
        <label htmlFor={"geoclock" + state?.id}>Pointeuse obligatoire</label>
        </div>
        {state?.geoClock &&
            <div className={"large w100 security-box flex-row"}>
              <input id={"securityService" + state?.id} type={"checkbox"} checked={state?.securityService&&state?.geoClock} onChange={handleSecurityService}/>
                <label htmlFor={"securityService" + state?.id}>Activer le module de sécurité</label>
            </div>
          }
        {state?.securityService && allUser &&
        <div className={"fullWidth"}>
          <Select
              className={"large w100"}
            styles={{menuPortal: (base) => ({...base, zIndex: 9999})}}
            menuPortalTarget={document.body}
            isMulti
            onChange={handleSecurityNumber}
            placeholder={"Selectionner les utilisateurs à prévenir en cas de danger"}
            options={allUserNumber}
            value={selectedSecurityNumbers}
          />
        </div>}
      </div>
      }
        <div className={"userOptions large w100"}>
            {
                !user?.authorities?.includes(constants.ADMIN) && user?.id &&
                <IconButton onClick={handleDelete} className={`action-btn ${state?.deleted ? "validation" : "cancel"}`}>
                    {state?.deleted ? "Activer" : "Désactiver"} l'utilisateur
                </IconButton>
            }
        <IconButton onClick={()=> state?.id? update() : createUser()} className={"action-btn validation"}>{state?.id? text.SAVE : text.CREATE}</IconButton>
        </div>
    </div>
}

/*function User({username}){
    return (
        <Fragment>
            <span className={"user-row flex-row"}>{username}</span>
        </Fragment>
    )
}*/

export default function UserManager({handleClick, userList}){
    const [users,setUsers]=useState([])
    const [filteredUsers,setFilteredUsers]=useState([])
    const [selected,setSelected]=useState("")
    useEffect(()=>{
        setUsers(userList)
        setFilteredUsers(userList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userList])

    function filterUser(e) {
        const name = e.target.value;
        setFilteredUsers(users.filter(user => user.username.includes(name)))
  }

    return <div className={"user-list secondary-section flex-column w50"}>
        <h2>{text.USER_LIST}</h2>
        <div className={"flex-row w100"}>
            <img alt={""} src={SearchImg} className={"searchIcon"}/>
            <input className={"large w100"} type={"text"} placeholder={text.ENTER_USERNAME} defaultValue={null} onChange={filterUser}/>
        </div>
        <ul className={"flex-column w100"}>
        {filteredUsers
            .map(u => {return {...u, username: capitalize(u.username)}})
            .sort((a, b) => a.username > b.username  ? 1 : -1)
            .map(u=>
            <li style={u.deleted === true ? {opacity: "50%"} : {}} key={v4()}>
                <IconButton
                    style={u.deleted === true ? {textDecoration: "line-through"} : {}}
                    className={`w100 action-btn neutral br05 ${selected === u.id ? "selected" : ""}`}
                    onClick={()=> {
                    handleClick({...u})
                    setSelected(u.id)
                }}
                >
                    <img alt={"edition"} src={EditIcon}/> {u.username}
                </IconButton>
            </li>)}
        </ul>
      <IconButton className={"action-btn validation"} onClick={()=>handleClick()}>{text.NEW_USER}</IconButton>
    </div>

}
