import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import {useParams} from "react-router-dom";
import GestionnaireSelector from "../util/GestionnaireSelector";
import {IconButton} from "@mui/material";
import PenIcon from "../../images/pen-edit-icon.png";
import Site from "../../images/site.png";
import ClientForm from "../forms/ClientForm";
import Modal from "react-responsive-modal";
import {text} from "../util/constants";
import {useSnackbar} from "notistack";
import Table from "../../table/Table";
import CheckBox from "../util/Checkbox";
import {useMediaQuery} from "react-responsive";

export default function Client() {

    const {id} = useParams()
    const api = new IbatApi();

    const toBillColumns =[
        {id: "id", isChecked: true, label: text.ID, type: "linkText", isFiltrable: true},
        {id: "type", isChecked: true, label: text.FILE_TYPE, type: "object"},
        {id: "status", isChecked: true, label: text.STATUS, type: "text"},
        {id: "site", isChecked: true, label: text.SITE, type: "text", isFiltrable: true},
        {id: "cp", isChecked: true, label: text.ZIPCODE, type: "text", isFiltrable: true},
        {id: "ville", isChecked: true, label: text.TOWN, type: "text", isFiltrable: true},
        {id: "address", isChecked: true, label: text.ADDRESS, type: "text", isFiltrable: true},
        {id: "dateIntervention", isChecked: true, label: text.DATE, type: "dateText", isFiltrable: true},
        {id: "estimate", isChecked: true, label: text.ESTIMATE, type: "text", isFiltrable: true},
        {id: "bill", isChecked: true, label: text.BILL, type: "text", isFiltrable: true},
        {id: "checkbox", isChecked: true, label: text.TO_BILL, type: "component", component: CheckBox, props: {onClick:addToInterventionToBill}},
    ]

    const [state, setState] = useState(
        {
            columns: toBillColumns,
            interventionList: undefined,
            pageSize: 50,
            pageNumber: 0,
            count: 0,
            lastQuery: new Date(),
            filters: {
                id: "",
                status: "",
                site: "",
                cp: "",
                ville: "",
                address: "",
                dateIntervention: "",
                estimate: "",
                bill: ""
            }
        })
    const [gestionnaire, setGestionnaire] = useState()
    const [sites, setSites] = useState([])
    const [interventionToBill, setInterventionToBill] = useState([])
    const [selectedInterventionToBill, setSelectedInterventionToBill] = useState([])
    const [createBill, setCreateBill] = useState(false)
    const [update, setUpdate] = useState(false)
    const [loadingSiteState, setLoadingSiteState] = useState("Loading")
    const [loading, setLoading] = useState("true")
    const [typing,setTyping]=useState(false)
    const [billPending, setBillPending] = useState(false)
    const [page, setPage] = useState(0)
    const [totalPage, setTotalPage] = useState(0)
    const abortController = new AbortController();
    const {enqueueSnackbar}=useSnackbar()
    const isMobile = useMediaQuery({ query: '(max-width: 915px)' })

    const siteColumns =  [
        {id: "id", isChecked: true, label: text.ID, type: "linkText"},
        {id: "name", isChecked: true, label: text.SITE, type: "text"},
        {id: "cp", isChecked: true, label: text.ZIPCODE, type: "text"},
        {id: "ville", isChecked: true, label: text.TOWN, type: "text"},
        {id: "address", isChecked: true, label: text.ADDRESS, type: "text"}
    ]

    useEffect(() => {
        getClient()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    useEffect(() => {
        getInterventionToBill(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typing, page])

    function mapInterventionToBill(interventions) {
        return interventions.map (d => {
            return {
                id: d.id,
                type: d.type,
                status: text.STATUS_TRADUCTION[d.status],
                site: d.devis.site.name,
                cp: d.devis.site.cp,
                ville: d.devis.site.ville,
                address: d.devis.site.address,
                dateIntervention: d.dateIntervention,
                estimate: d.estimate,
                bill: d.bill
            }
        })
    }

    function addToInterventionToBill(e, id) {

        setSelectedInterventionToBill(prevSelected => {
            if (e.target.checked) {
                return [...prevSelected, {id: id}];
            } else {
                return prevSelected.filter(i => i.id !== id);
            }
        });

    }

    function createOdooBill(interventionType, tax) {
        setBillPending(true)
        api.createBill(selectedInterventionToBill, interventionType, tax)
            .then(d=>{
            if(d){
                setBillPending(false)
                enqueueSnackbar('Les factures ont bien été créées', {variant: "success"})
                setInterventionToBill([])
                setSelectedInterventionToBill([])
                setCreateBill(false)
                getInterventionToBill(id)
            }
        })
            .catch(_ =>{
            setBillPending(false)
            enqueueSnackbar('Une erreur est survenue lors de la création des factures', {variant: "error"})
        })
    }

    function handleSave(d){
        setUpdate(!update)
        return api.updateClient(d).then(_=>getClient())
    }

    function getClient(){
        api.getClientById(id).then(d => {
            setState(state => ({...state, ...d}))
        })
        // getInterventionToBill(id)
        api.getClientSite(id)
            .then(d=>{
            setSites(d.content.sort((a,b)=>a?.id > b?.id ? 1 : -1))
            setLoadingSiteState("Done")
        })
            .catch(_ => {
            setLoadingSiteState("Error")
        })
        api.getClientGestionnaire(id)
            .then(d=>{
            setGestionnaire(d)

        })
    }

    function getInterventionToBill(id){
        abortController.abort();
        setLoading(true)
        var sendedAt = new Date()
        api.getInterventionToBill(id, page, {
            ...state.filters
        }, { signal: abortController.signal })
            .then(d=>{
                setLoading(false)
                if(!state.lastQueryAt || sendedAt >= state.lastQueryAt){
                    setInterventionToBill(mapInterventionToBill(d.content))
                    setTotalPage(d.totalPages)
                }
            })
    }


    function generatecsv() {
        api.generateCsvForInterventionToBill(id, {...state.filters})
    }

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        setState( ({...state, filters: {...state.filters, [name]: value,sort: name}, lastQueryAt: new Date()}))
    }

    return (
        <div className={"page scrollable"}>
            <div className={"flex-column client-bean"}>
                <div className={"secondary-section client-header w100 flex-column"}>
                    <h1 className={"flex-row"}>{text.CLIENT}</h1>
                    <div className={"secondary-section"}>
                        <div><IconButton className={"tr-corner"} onClick={()=>setUpdate(true)}>
                            <img alt={""} src={PenIcon}/>
                        </IconButton></div>
                        <div className={"flex w100 client-grid"}>
                                <div className={"bord1 client-info"} title={"Nom : "}> {state.name}</div>
                                <div className={"bord1 client-info"} title={"Numéro de Téléphone : "}>{state.phone}</div>
                                <div className={"bord1 client-info"} title={"Mail : "}> {state.mail}</div>
                            {gestionnaire &&<div id={"gestionnaire"} className={"bord1  client-info"} title={"Gestionnaire : "}><div className={"gest-input"}> { !gestionnaire|| gestionnaire.id<0?<GestionnaireSelector onSet={getClient} id={id} />:gestionnaire.name}</div></div>}

                            <div className={"bord1 client-info"} title={"Adresse : "}>{state.address}{state.adress2 ? ' '+state.adress2 : ""},{state.cp},{state.ville}</div>
                                <div className={"bord1 client-info"} title={"Interlocuteur : "}>{state.interlocutorName}</div>
                                <div className={"bord1 client-info"} title={"Téléphone : "}>{state.interlocutorPhone}</div>
                                <div className={"bord1 client-info"} title={"Mail : "}>{state.interlocutorMail}</div>
                                <div className={"bord1 client-info"} title={"Fonction : "}>{state.interlocutorPoste}</div>
                        </div>

                    </div>
                    <Modal open={update} onClose={()=>setUpdate(false)}>
                        <ClientForm {...state} idGestionnaire={gestionnaire?.id} onSave={handleSave} onCancel={()=>setUpdate(!update)}/>
                    </Modal>
                </div>
                {
                    state.siren === "377768601" && !isMobile &&
                    <>
                        <h1>{text.INTERVENTION_TO_BILL}</h1>
                        {
                            <div className={"table w100"}>
                                <Table
                                    hideFilter={true}
                                    tabName={"interventions"}
                                    columns={state.columns.filter(c => state.siren === "377768601" || c.id !== "checkbox")}
                                    list={interventionToBill}
                                    filter={state.filters}
                                    onHandleFilter={handleFilter}
                                    loading={loading}
                                    onEndTyping={setTyping}
                                />
                                {
                                    state.siren === "377768601" &&
                                    <>

                                        <IconButton className={"action-btn validation sticky-bottom"} onClick={generatecsv} style={{float: "left"}}>
                                            Extraction tableur
                                        </IconButton>

                                        <div style={{position: "sticky", bottom: "4rem", margin: "auto"}} className={"flex-row widfit"}>
                                            <IconButton style={{color: "white"}} className={"action-btn neutral"} disabled={page == 0} onClick={() => setPage(page-1)}>&#x3C; Page précédente</IconButton>
                                            <IconButton style={{color: "white"}} className={"action-btn neutral"} disabled={true}>{page+1}/{totalPage}</IconButton>
                                            <IconButton style={{color: "white"}} className={"action-btn neutral"} disabled={page == totalPage-1} onClick={() => setPage(page+1)}>Page suivante &#x3E;</IconButton>
                                        </div>


                                        <IconButton className={"action-btn validation sticky-bottom"}
                                                    disabled={selectedInterventionToBill.length === 0}
                                                    onClick={() => setCreateBill(true)}>
                                            Générer {selectedInterventionToBill.length} facture{`${selectedInterventionToBill.length > 1 ? "s" : ""}`}
                                        </IconButton>
                                        <Modal open={createBill} onClose={() => setCreateBill(false)}>
                                            <div className={"flex-column"}>
                                                <h2>Déplacement + Main d'oeuvre</h2>
                                                <div className={"inline-flex"}>
                                                    <IconButton className={"action-btn validation"}
                                                                disabled={billPending === true}
                                                                onClick={() => createOdooBill("full", 10)}>
                                                        350€ TVA à 10%
                                                    </IconButton>
                                                    <IconButton className={"action-btn validation"}
                                                                disabled={billPending === true}
                                                                onClick={() => createOdooBill("full", 20)}>
                                                        350€ TVA à 20%
                                                    </IconButton>
                                                </div>
                                                <h2>Déplacement seul</h2>
                                                <div className={"inline-flex"}>
                                                    <IconButton className={"action-btn validation"}
                                                                disabled={billPending === true}
                                                                onClick={() => createOdooBill("partial", 10)}>
                                                        250€ TVA à 10%
                                                    </IconButton>
                                                    <IconButton className={"action-btn validation"}
                                                                disabled={billPending === true}
                                                                onClick={() => createOdooBill("partial", 20)}>
                                                        250€ TVA à 20%
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Modal>
                                    </>
                                }
                            </div>
                        }
                    </>
                }

                <div className={"secondary-section w100"} ><h1>{text.CLIENT_SITE_LIST}</h1>
                    {
                        loadingSiteState === "Loading" ?
                            <div>
                                Chargement en cours ...
                            </div>
                            :
                            (
                                loadingSiteState === "Error" ?
                                    <div>
                                        Une erreur est survenue lors du chargement des sites du client ...
                                    </div>
                                    :
                                    <ul className={"flex-row auto-line"}>
                                        {
                                            sites.map(s => <div className={"secondary-section widfit"}> <li>

                                                {s.name} <br /><div className={"secondary-section flex-row"} ><img src={Site}/><a href={`site/${id}`}>{s.address}, {s.cp}, {s.ville}</a></div>
                                            </li></div>)
                                        }
                                    </ul>


                            )
                    }
                </div>
            </div>
        </div>
    )
}
