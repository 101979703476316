import IbatApi from "../../utils/IbatApi";
import constants, {text} from "../util/constants";
import React from "react";
import {IconButton} from "@mui/material";

export default function EditInterventionAvailabilityDate({id, day,update}) {

    const [date, setDate] = React.useState(0);

    let api = new IbatApi();

    const updateDate = () => {

        const timestamp = new Date(date).getTime();
        api.updateAvailabilityDate(id, timestamp)
            .then(r => {
                update(timestamp)
            })
            .catch(error => {
                console.error(error)
            })
    }


    if(!api.hasRole(constants.PERMISSION_PM)){
        return <h3>{text.NO_RIGHT}</h3>
    }
    return (
        <div className={"flex-column"}>
            <h2>Éditer la date de disponibilité</h2>
            <input type="date" value={date ?? day} onChange={(e)=> setDate(e.target.value)}/>
            <div className={"flex-row"}>
                <IconButton className={"action-btn cancel"} onClick={update}>Annuler</IconButton>
                <IconButton className={"action-btn validation"} onClick={updateDate}>Valider</IconButton>
            </div>
        </div>
    )
}