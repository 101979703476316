import axios from "axios";
import constants, {text} from "../components/util/constants";
import {timeToDate} from "../helpers/timeToDate";


export default class IbatApi {

    constructor(clientToken) {
        this.api_token = null;
        this.client = null;
        this.api_url = process.env.REACT_APP_API_ENDPOINT
        // this.api_url = "https://backend.ebat.tegeo.fr"
        // this.api_url = "http://localhost:8002"
        this.domain = process.env.REACT_APP_DOMAIN
        this.clientToken = clientToken
    }


    getCookie = (cname) => {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        if (document.cookie.length === 0) {
            return ""
        }
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    init = () => {
        this.api_token = this.getCookie("Token");

        let headers = {
            Accept: "*"
        };
        if (this.clientToken) {
            headers["Access-Token"] = `${this.clientToken}`;
        }

        if (this.api_token) {
            headers.Authorization = `${this.api_token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 310000,
            headers: headers,
        });

        this.client.interceptors.response.use(
          response => {
            return response
          },
          error => {
            if (error.response?.status === 401) {
                window.dispatchEvent(new CustomEvent('unauthorizedError'));
            }
            return Promise.reject(error)
          }
        )

        return this.client;
    };

    getAuthorizationHeader() {
           return {Authorization: this.getCookie("Token")}
    }
    getUrl() {
           return this.api_url
    }

    getPrefs(key) {
        return localStorage.getItem(key)
    }
    setLogoCookie(file){
      localStorage.setItem("Logo", file)
    }
    getLogoCookie(){
      return localStorage.getItem("Logo")
    }

    setCookie(k, v){
        localStorage.setItem(k, v)
    }
    getSettingsCookie(k){
        return localStorage.getItem(k)
    }

    setStyleCookie(file){
      localStorage.setItem("Style", file)
    }
    getStyleCookie(){
        const style = localStorage.getItem("Style")
        if (style !== "null") {
            return style
        }
        return "light-"
    }
    addClientInteraction = (id, interactionClient, devis, number) => {
        return this.init().put(`/core/${devis?"devis":"intervention"}/${id}/interractionClient${number ? `?number=${number}` : "" }`, interactionClient)
            .then(resp => resp.data)
    }


    getClientInteraction = (id,hide) => {
        return this.init().get(`/core/${hide?"devis":"intervention"}/${id}/interractionClient`)
            .then(resp => resp.data)
    }
    getFilteredInterventions = (params, forSupplier) => {
        return this.init().get(`/core/intervention/filter${forSupplier ? "/supplyManager" : ""}`, {params: params ? params : {}})
            .then(respose => {
                return {
                    interventions: respose.data.content.map(e => this.normalize(e)),
                    count: respose.data.totalElements
                }
            })
            .catch(e => {
                console.log(e)
            })

    };
    getFilterSupply = (params) => {
        return this.init().get("/core/supplyElement/filter", {params: params ? params : {}})
            .then(respose => {
              return {
                interventions: respose.data
              }
            })

    };

    getTeams = (teamName) => {
        return this.init().get("/core/team/", {params: {teamName: teamName}}).then(rsp => rsp.data)
    }

    getSite = (id) => {
        return this.init().get("/core/site/" + id).then(resp => resp.data);
    };

    getSiteFilterd = (params) => {
        return this.init().get("/core/site/filter", {params: params ? params : {}}).then(resp => resp.data);
    };

    findSite(siteName) {
        return this.init().get("/core/site/find", {params: {site: siteName}}).then(resp => resp.data);
    }
    findCompany(companyName) {
        return this.init().get("/core/company/find", {params: {company: companyName}}).then(resp => resp.data);
    }

    getClientById = (id) => {
        return this.init().get("/core/client/" + id).then(resp => resp.data)
    }

    findClient(clientName) {
        return this.init().get("/core/client/find", {params: {name: clientName}}).then(resp => resp.data)
    }
    findInsurance(insuranceName) {
        return this.init().get("/core/insurance/find", {params: {name: insuranceName}}).then(resp => resp.data)
    }
    getAllInsurance() {
        return this.init().get("/core/insurance").then(resp => resp.data)
    }

    getIntervention = (id) => {
        return this.init().get("/core/intervention/" + id).then(response => response.data)
    }

    normalize = (
        {dateIntervention,devis,estimateDuration, secteur, endIntervention, id, status, team, type,estimate,bill,event, prioritize, riskAnalysisLevel, headEventDate, interactions}
    ) => {
        //
        let intervention=dateIntervention??event?.start??headEventDate??0
        return {
            intervention: {
                id: id,
                value: intervention,
                name: intervention <= 0 || intervention===null ? "-" : new Date(intervention).toLocaleDateString()+" "+new Date(intervention).toLocaleTimeString()
            },
            reception: new Date(devis.dateReception).toLocaleDateString(),
            devis:{
                id: devis.id,
                name: devis.clientIdBCommande?devis.clientIdBCommande:constants.NULL,
                warning: !devis.clientIdBCommande
            },
            end: (endIntervention === 0 || !endIntervention) ? "-" :new Date(endIntervention).toLocaleDateString()+" "+new Date(endIntervention).toLocaleTimeString(),
            rapport: text.DOWNLOAD_REPORT,
            id: id,
            bill: {id:id,name:bill},
            estimate: {id:id,name:estimate},
            secteur:secteur ? {id: secteur.id, name: secteur.name}:{id: -1, name: constants.NULL},
            client: {id: devis?.client?.id, name: devis?.client?.name ?? "-" ,warning: devis.gestionnaire.id<0},
            gestionnaire: {id: devis.gestionnaire.id, name: devis.gestionnaire.name},
            estimateDuration: {id:id, name:`${timeToDate(estimateDuration*1000)}`,value:estimateDuration},
            site: {
                id: devis.site?.id,
                name: devis.site?.name ?? "-",
                warning:(!devis.site?.latitude || !devis.site || devis.site.temporary),
                contactPhone: devis.site?.contactPhone
            },
            phone: devis.site?.contactPhone?.length>0?devis.site.contactPhone[0]:null,
            interactions: {warning:!interactions && status !== "CANCEL"},
            cp:  devis.site?.cp,
            ville:devis.site?.ville,
            address: {name: devis.site?.address, warning:(!devis.site?.latitude || !devis.site), temporary: devis.site?.temporary, voie: devis.site.address + ", " + devis.site.cp + ", " + devis.site.ville ,},
            status: text.STATUS_TRADUCTION[status],
            team: {id: team.id, name: team.name},
            supply:{id: devis?.supply?.id, name: devis?.supply?.ready===constants.READY ? text.READY : text.INCOMPLETE},
            longitude: devis.site?.longitude,
            latitude: devis.site?.latitude,
            type: {id:type.id,name:type.name},
            prioritize: prioritize ?? false,
            riskAnalysisLevel: riskAnalysisLevel,
            insurance: devis?.insurance?.name ?? "-"
        }
    }

    normalizeQuotation = ({devisId, devisName, interventionId, name, odooId, id, siteId, siteName, facturationEntityId, facturationEntityName, facturationEntityType, status, dateSent, odooRef, billEntityName, billEntityId}) => {
        let normalized = {
            id: id,
            name: {id: id, name: name ?? "-", target: "quotation"},
            odooRef: {id: id, name: odooRef ?? "-", target: "quotation"},
            devis: {id: devisId, name:devisName ?? devisId},
            Interventions: {id: interventionId, name : interventionId},
            site: {id: siteId, name: siteName ?? siteId},
            facturationEntity: billEntityId ? {id: billEntityId, name: billEntityName, target: "client"} : {id: facturationEntityId, name: facturationEntityName, target: "client"},
            status: text.STATUS_TRADUCTION[status],
            dateSent: {id: id, name: dateSent ? new Date(dateSent).toLocaleDateString(): "-", target : "quotation"}
        }
        return normalized
    }

    getTypes() {
        return this.init().get("/core/interventionType").then(resp => resp.data)
    }

    createSite(siteApi) {
        return this.init().post("/core/site", siteApi).then(resp => resp.data)
    }

    getSecteur() {
        return this.init().get("/core/secteur").then(resp => resp.data)
    }
    getSecteurWithHidden() {
        return this.init().get("/core/secteur/withHidden").then(resp => resp.data)
    }

    getGestionnaire() {
        return this.init().get("/core/myUser/gestionnaire").then(resp => resp.data)
    }

    createClient(clientApi) {
        return this.init().post("/core/client", clientApi).then(resp => resp.data)
    }
    createClientList(clientApi) {
        return this.init().post("/core/client/list", clientApi).then(resp => resp.data)
    }
    updateClientList(clientApi) {
        return this.init().put("/core/client/updateList", clientApi).then(resp => resp.data)
    }
    /*updateOdooClientList(client) {
        return this.init().post("/core/client/updateList", client).then(resp => resp.data)
    }
    updateOdooSiteList(client) {
        return this.init().post("/core/site/updateList", client).then(resp => resp.data)
    }*/

    creatUser(utilisateur) {
        return this.init().post("/auth/user",utilisateur).then(resp => resp.data)
    }

    getAllUser() {
        return this.init().get("/auth/user").then(resp => resp.data)
    }

    updateUser(utilisateur) {
        return this.init().put("/auth/update",utilisateur).then(resp => resp.data)
    }
    deleteUser(uid) {
        return this.init().delete(`/auth/user/${uid}`).then(resp => resp.data)
    }

    creatIntervention(id, interventionApi) {
        let isMandatory= +interventionApi.intervention.idTypeIntervention===+constants.INTERVENTION_ENTRETIEN
        return this.init().post(`/core/site/${id}/devis?isMandatory=${isMandatory}`, interventionApi).then(resp => resp.data)
    }

    setInterventionTeam(idIntervention, temaId) {
        return this.init().put("/core/intervention/" + idIntervention + "/team?teamId=" + temaId).then(resp => resp.data)
    }
    removeInterventionTeam(idIntervention) {
        return this.init().delete("/core/intervention/" + idIntervention + "/team").then(resp => resp.data)
    }

    setInterventionDate(idIntervention, dateIntervention) {
        return this.init().put("/core/intervention/" + idIntervention + "/dateIntervention?date=" + dateIntervention).then(resp => resp.data)
    }
    removeInterventionDate(idIntervention) {
        return this.init().delete("/core/intervention/" + idIntervention + "/dateIntervention").then(resp => resp.data)
    }

    prioritizeIntervention(id, value) {
        return this.init().put(`/core/intervention/prioritize/${id}/${value}`).then(resp => resp.data)
    }

    getSupply(id) {
        return this.init().get("/core/intervention/" + id + "/supply").then(r => r.data)
    }

    getClient(params) {
        return this.init().get("/core/client/filter", {params: params ? params : {}}).then(resp => resp.data)
    }
    getClientType(){
      return this.init().get("/core/client/getClientType").then(resp=>resp.data)
    }

    getConstructionTypesMachinery() {
        return this.init().get("/core/constructionMachineryType").then(resp => resp.data)
    }

    getContructionTypesDocument() {
        return this.init().get("/core/constructionDocumentType").then(resp => resp.data)
    }

    getAdministrationTypesDocument() {
        return this.init().get("/core/administrationDocumentType").then(resp => resp.data)

    }

    getConstructionTypesMaterials() {
        return this.init().get("/core/constructionMaterialType").then(resp => resp.data)
    }

    addDocumentSupply(interventionId, id, document) {
        return this.init().put(`/core/supply/${id}/document?interventionId=${interventionId}`, document).then(resp => resp.data)
    }
    addAdminDocumentSupply(id, document) {
        return this.init().put("/core/supply/" + id + "/adminDocument", document).then(resp => resp.data)
    }
    addMachinerySupply(interventionId, id, machinery) {
        return this.init().put(`/core/supply/${id}/machinery?interventionId=${interventionId}`, machinery).then(resp => resp.data)

    }
    addMaterialSupply(id, material) {
        return this.init().put(`/core/supply/${id}/material`, material).then(resp => resp.data)
    }
    renameSupply(id, name){
      let params = {params: {name:name}}
      return this.init().get(`/core/supplyElement/${id}/renameSupply`, params).then(resp => resp.data)
    }
    renameSupplier(id, name){
      let params = {params: {name:name}}
      return this.init().get(`/core/supplyElement/${id}/renameSupplier`, params).then(resp => resp.data)
    }
    changeSupplyStatus(id, status){
      let params = {params: {status:status}}
      return this.init().get(`/core/supplyElement/${id}/changeStatus`, params).then(resp => resp.data)
    }
  changeQty(id, name){
    let params = {params: {qty:name}}
    return this.init().get(`/core/supplyElement/${id}/changeQty`, params).then(resp => resp.data)
  }


  getCompanyById = (id) => {
    return this.init().get("/core/company/" + id).then(resp => resp.data)
  }
  getInterventionCountByCompany = (id) => {
    return this.init().get("/core/devis/company/countIntervention/" + id).then(resp => resp.data)
  }
  getStats(id, params){
    return this.init().get("/core/intervention/company/stats", {params: params ? params : {}}).then(resp => resp.data)
  }
  getDelay(id, params){
    return this.init().get("/core/intervention/company/delay", {params: params ? params : {}}).then(resp => resp.data)
  }
  getCA(id, params){
    return this.init().get("/core/intervention/company/CA", {params: params ? params : {}}).then(resp => resp.data)
  }


    sendAdminDocument(id, state) {
        return this.init().put("/core/administrationDocument/" + id, state).then(resp => resp.data)

    }
    sendAdminDocumentDocs(id, docs) {
        return this.init().put(`/core/administrationDocument/${id}/documents`, docs).then(resp => resp.data)
    }

    sendDocument(id, state) {
        return this.init().put("/core/constructionDocument/" + id, state).then(resp => resp.data)
    }

    sendDocumentDocs(id, docs) {
        return this.init().put(`/core/constructionDocument/${id}/documents`, docs).then(resp => resp.data)
    }


    sendMachinery(id, state) {
        return this.init().put("/core/constructionMachinery/" + id, state).then(resp => resp.data)
    }
    sendMachineryDocs(id, docs) {
        return this.init().put(`/core/constructionMachinery/${id}/documents`, docs).then(resp => resp.data)
    }


    sendMaterial(id, state) {
        return this.init().put("/core/constructionMaterial/" + id, state).then(resp => resp.data)
    }
    sendMaterialDocs(id, docs) {
        return this.init().put(`/core/constructionMaterial/${ id}/documents`, docs).then(resp => resp.data)
    }

    deleteMachinery(supplyId, idElem, interventionId) {
        return this.init().delete(`/core/supply/${supplyId}/machinery?idElem=${idElem}&interventionId=${interventionId}`).then(resp => resp.data)
    }

    deleteMaterial(supplyId, idElem) {
        return this.init().delete("/core/supply/" + supplyId + "/material?idElem="+idElem).then(resp => resp.data)
    }

    deleteDocument(interventionId, supplyId, idElem) {
        return this.init().delete(`/core/supply/${supplyId}/document?idElem=${idElem}&interventionId=${interventionId}`).then(resp => resp.data)
    }

    deleteAdminDocument(supplyId, idElem) {
        return this.init().delete("/core/supply/" + supplyId + "/admindocument?idElem="+idElem).then(resp => resp.data)
    }

    createOperationType(operationType,jobType) {
        return this.init().post(`/metier/operationType/${jobType}`,operationType).then(resp=>resp.data)
    }

    createOperation(idIntervention, operationType) {
      return this.init().put(`/core/intervention/${idIntervention}/mandatory`,operationType)
    }

    /*updateOperation(id, data) {
        this.init().put(`/core/operation/${id}/gestionnaire?comments=${data.commentaireGestionnaire}`)
        return this.init().put(`/core/operation/${id}/rt?comments=${data.commentaireRT}`)
    }*/
    updateOperation(target, id, data) {
        return this.init().put(`/core/operation/${id}/${target}?comments=${data}`).then(resp => resp.data)
    }

    getSiteIntervention(id) {
        return this.init().get("/core/site/" + id + "/intervention").then(resp => resp.data)
    }

    getClientSite(id) {
        return this.init().get("/core/client/" + id + "/site").then(resp => resp.data)
    }
    getInterventionToBill(id, page, params) {
        return this.init().get("/core/intervention/toBill/client/" + id + "?page=" + page, {params: params ? params : {}}).then(resp => resp.data)
    }

    generateCsvForInterventionToBill(id, params) {
        return this.init().get(`/core/intervention/toBill/client/${id}/extract`,{params: params ? params : {}})
            .then(d=> {
                let data = new Blob([d.data],{type:"octet/stream"});
                var url  = window.URL.createObjectURL(data);
                let ahref = document.createElement("a");
                ahref.setAttribute("href",url);
                ahref.setAttribute("download","result.csv")
                ahref.click()
            })
    }

    getClientGestionnaire(idClient){
        let myUserId = this.getMyUserId();
        return this.init().get(`/core/client/${idClient}/gestionnaire?idUser=${myUserId}`).then(resp=>resp.data)
    }

    getToken(){
        return this.getCookie("Token")
    }

    setClientConcern(id, clientConcern) {
        return this.init().put(`/core/devis/${id}/clientConcern?clientConcern=${clientConcern}`).then(resp => resp.data)
    }

    setInterventionDuration(id,duration) {
        return this.init().put("/core/intervention/" + id + "/estimateDuration?duration=" + duration).then(resp => resp.data)
    }
    setInterventionRiskAnalysisLevel(id,ral) {
        return this.init().put("/core/intervention/" + id + "/riskAnalysisLevel?level=" + ral).then(resp => resp.data)
    }

    importDevis(idDevis) {
        return this.init().get(`/core/devis/import?devisRef=${idDevis}`).then(resp => resp.data)
    }

    getMyOperationType(jobType) {
        return this.init().get(`/core/couchbase/listMyOperationType/?jobType=${jobType}`).then(resp => resp.data);
    }

    updateSite(siteId, state) {
        return this.init().put(`/core/site/${siteId}`,state).then(resp => resp.data);
    }
    updateSiteMail(siteId, email) {
        return this.init().put(`/core/site/${siteId}/mail?mail=${email}`).then(resp => resp.data);
    }

    setInterventionSecteur(id, secteurId) {
        return this.init().put(`/core/intervention/${id}/secteur?secteurId=${secteurId}`).then(resp => resp.data);
    }
    login(loginData){
        return this.init().post("/auth/login",loginData)
            .then(resp=>{
                document.cookie = `Token=${resp.headers.token};expires=${new Date(new Date().getTime() + (24 * 60 * 60 * 1000))};path=/;domain=${this.domain}`;
                return document.cookie
        })
            .catch((error)=>{
                error?.response && alert(text.INVALID_LOGIN)
            })
    }

    deconexion(){
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, "=;expires=" + new Date().toUTCString() + `;path=/;domain=${this.domain}`);
        });
        window.location="/login"
    }

    getMyUserId(){
        let data = this.getTokenData();
        return data.userId
    }

    getTokenData(){
        let token = this.getToken();
        if (token && token!=='null' && token!=='undefined') {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join(''));
            return JSON.parse(jsonPayload)
        }

    }

    findDevis(idbcomande) {
        return this.init().get(`/core/devis/find?idBcommande=${idbcomande}`).then(resp=>resp.data)
    }

    getDevis(id) {
        return this.init().get(`/core/devis/${id}`).then(resp=>resp.data)
    }
    getDevisSupply(id) {
        return this.init().get(`/core/devis/${id}/supply`).then(resp=>resp.data)
    }
    getQuotationSupply(id) {
        return this.init().get(`/core/quotation/${id}/supply`).then(r => r.data)
    }

    addIntervention(idDevis, intervention) {
        let isMandatory = intervention.idTypeIntervention==constants.INTERVENTION_ENTRETIEN;
        return this.init().put(`/core/devis/${idDevis}/intervention?isMandatory=${isMandatory}`,intervention).then(resp=>resp.data)
    }
    async genBase64(blob){
        return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        })
    }

    getFile(fileName){
        return this.init().get(`/core/${fileName}`,{ responseType: 'blob' })
            .then(async d=>({url:URL.createObjectURL(d.data),b64:await this.genBase64(d.data)}))
    }
    getFileList(fileList){
        return this.init().post(`/core/utils/downloadImages`, fileList).then(resp => resp.data)
    }
    getFileListStream(fileList){
        return this.init().post(`/core/utils/streamImages`, fileList, {responseType: 'stream',}).then(resp => resp.data)
    }
    getAPK(fileName){
        return this.init().get(`/auth/${fileName}`,{ responseType: 'blob' })
            .then(async d=>({url:URL.createObjectURL(d.data),b64:await this.genBase64(d.data)}))
    }
    getAudio(fileName){
        return this.init().get(`/core/${fileName}`,{ responseType: 'arraybuffer' })
            .then(resp=>new Uint8Array(resp.data))
            .then(uintArray=>uintArray.reduce((data, byte) => data + String.fromCharCode(byte),'',))
            .then(uintString=>btoa(uintString))
            .then(base64=>`data:audio/mp3;base64,${base64}`)
    }

    getAllRa() {
        return this.init().get(`/core/couchbase/listAllRAConfig`).then(resp=>resp.data)
    }
    getAllQuestion() {
        return this.init().get(`/core/couchbase/listAllQuestion`).then(resp=>resp.data)
    }
    getAllAnswers() {
        return this.init().get(`/core/couchbase/listAllAnswer`).then(resp=>resp.data)
    }
    getElementType() {
        return this.init().get(`/core/couchbase/listElementType`).then(resp=>resp.data)
    }
    getOperationType() {
        return this.init().get(`/core/couchbase/listOperationType`).then(resp=>resp.data)
    }
    getElementStateSuggestion(datatype) {
        return this.init().get(`/core/couchbase/listElementStateSuggestion`, {params: {dataType: datatype}}).then(resp=>resp.data)
    }
    removeEt(id){
      return this.init().delete(`/core/couchbase/removeEt/${id}`).then(resp=>resp.data)
    }
    removeOt(id){
      return this.init().delete(`/core/couchbase/removeOt/${id}`).then(resp=>resp.data)
    }
    removeEs(id){
      return this.init().delete(`/core/couchbase/removeEs/${id}`).then(resp=>resp.data)
    }
    saveEt(et){
    return this.init().post(`/core/couchbase/crudEt`, et).then(resp=>resp.data)
  }
    saveOt(ot){
    return this.init().post(`/core/couchbase/crudOt`, ot).then(resp=>resp.data)
  }
    saveEs(es){
    return this.init().post(`/core/couchbase/crudEs`, es).then(resp=>resp.data)
  }

    getJobTypes(){
      return this.init().get(`/core/couchbase/getJobTypes`).then(resp=>resp.data)
    }
    getJobTypesOfCompany(){
      return this.init().get(`/core/couchbase/getJobTypesOfCompany`).then(resp=>resp.data)
    }
    saveJobType(jt){
    return this.init().post(`/core/couchbase/crudJobType`, jt).then(resp=>resp.data)
  }


    sendRAAnswer(answer) {
          return this.init().post(`/core/couchbase/crudAnswer`, answer).then(resp=>resp.data)
    }
    sendRAQuestion(question) {
          return this.init().post(`/core/couchbase/crudQuestion`, question).then(resp=>resp.data)
    }
    sendRAConfig(raConfig) {
          return this.init().post(`/core/couchbase/crudRaConfig`, raConfig).then(resp=>resp.data)
    }

    createQuestion(jobType, question) {
        return this.init().post(`/metier/securityQuestion/${jobType}`,question).then(resp=>resp.data)
    }

    addAnswer(questionId, answer) {
        return this.init().put(`/metier/securityQuestion/${questionId}/answer`,answer).then(resp=>resp.data)
    }

    addConflict(questionId, conflict) {
        return this.init().put(`/metier/securityQuestion/${questionId}/conflicts`,conflict).then(resp=>resp.data)
    }

    getAllPicture(interventionId) {
        return this.init().get(`/core/intervention/${interventionId}/pictures`).then(resp=>resp.data)
    }

    cancelIntervention(id, cause) {
        return this.init().delete(`/core/event/intervention/${id}/${cause}`)
    }

    getDevisIntervention(id) {
        return this.init().get(`/core/devis/${id}/intervention`).then(resp=> {
            return resp.data
        })

    }

    addDevisDocument(id, data) {
        return this.init().put(`/core/devis/${id}/document`,data)
    }

    setDevisClientIdBoncommande(id, clientIdBCommande) {
        return this.init().put(`/core/devis/${id}/clientCommande?clientIdBCommande=${clientIdBCommande}`)

    }

    addTeamInteraction(id, data) {
        return this.init().put(`/core/intervention/${id}/interractionTeams?message=${data}`)
    }

    getAllGestionnaire(){
        return this.init().get("/core/myUser/gestionnaire").then(d=>d.data)
    }
    setClientGestionnaire(id,idGestionnaire){
        return this.init().put(`/core/client/${id}/gestionnaire?idGestionnaire=${idGestionnaire}`).then(d=>d.data)
    }

    addPrerequisite(id, questionId, answerId) {
        return this.init().put(`/metier/securityQuestion/${id}/prerequisite?questionId=${questionId}&answerId=${answerId}`).then(d=>d.data)
    }

    setStateLevel(id,value) {
        return this.init().put(`/core/operation/${id}/stateLevel?stateLevel=${value}`).then(d=>d.data)

    }

    addReport(id, blobUrl) {
        return axios.create({timeout: 310000}).get(blobUrl,{responseType: 'blob'}).then(pdfFile=>{
            const reader = new FileReader();
            reader.readAsDataURL(pdfFile.data);
            reader.onload=()=>{
                const b64 = reader.result.replace(/^data:.+;base64,/, '');
                const data=[{type:'pdf',data:b64}]
                return this.init().put(`/core/intervention/${id}/report`,data).then(d=>d.data)
            }
            }
        )
    }

    setInterventionStatus(id,status){
        return this.init().put(`/core/intervention/${id}/status?status=${status}`).then(d=>d.data)
    }

    setBill(id,billName){
        return this.init().put(`/core/intervention/${id}/bill?bill=${billName}`).then(d=>d.data)

    }

    askQuestion(id,question) {
        return this.init().put(`/core/operation/${id}/question?question=${question}`).then(d=>d.data)
    }

    setVisibleOperation(id,visible) {
        return this.init().put(`/core/operation/${id}/visible?visible=${visible}`).then(d=>d.data)
    }

    saveObject(id, object) {
        return this.init().put(`/core/intervention/${id}/object?commentaire=${object}`).then(d=>d.data)

    }

    saveResume(id, resume) {
        return this.init().put(`/core/intervention/${id}/resume?commentaire=${resume}`).then(d=>d.data)
    }
    saveConclusion(id, resume) {
        return this.init().put(`/core/intervention/${id}/conclusion?commentaire=${resume}`).then(d=>d.data)
    }

    setVisible(picId,visible) {
        return this.init().put(`/core/picture/${picId}?visible=${visible}`).then(d=>d.data)

    }

    addPicture(opId,picIds) {
        return this.init().put(`/core/operation/${opId}/front/picture`,picIds).then(d=>d.data)
    }

    getTeamClock(id,start,end) {
        return this.init().get(`/core/team/${id}/clock?start=${start}&end=${end}`).then(d=>d.data)
    }

    getTeamPosition(id,start,end) {
        return this.init().get(`/core/team/${id}/position?start=${start}&end=${end}`).then(d=>d.data)
    }

    getTeamPositionDuringIntervention(id, begin, end){
        return this.init().get(`/core/intervention/rh?teamId=${id}&begin=${begin}&end=${end}`).then(d=>d.data)

    }

    getProductMaker() {
        return this.init().get(`/metier/productMaker/`).then(d=>d.data)
    }

    getProductUtilities() {
        return this.init().get(`/metier/productUtility/`).then(d=>d.data)
    }

    createCustomAnswer(customAnswer) {
        return this.init().post(`/metier/customAnswer`,customAnswer).then(d=>d.data)
    }

    /*getProducts() {
        return this.init().get(`/metier/product/`).then(d=>d.data)
    }*/

    createProduct(product) {
        return this.init().post(`/metier/product/`,product).then(d=>d.data)
    }

    getHazardStatement() {
        return this.init().get(`/metier/hazardStatement/`).then(d=>d.data)
    }

    addHazardStatement(id, selectedHazard) {
        return this.init().put(`/metier/product/${id}`,selectedHazard).then(d=>d.data)

    }

    genInterventionPdf(state, persist) {
        let headers={Authorization:this.api_token};
         let client = axios.create({
            baseURL: this.api_url,
            timeout: 900000,
            headers: headers,
        })
         return client.post(`/pdf/interventionReport`,state,{responseType: 'blob'}).then(d=>d.data)
    }
    genSecurityPdf(state) {
        let headers={Authorization:this.api_token};
         let client = axios.create({
            baseURL: this.api_url,
            timeout: 900000,
            headers: headers,
        })
         return client.post(`/pdf/securityReport`,state,{responseType: 'blob'}).then(d=>d.data)
    }

    getCustomAnswer() {
        return this.init().get("/metier/customAnswer").then(d=>d.data)
    }

    hasRole(role){
        const authorities = this.getTokenData()?.authorities;
        const roles= authorities
        return roles?.includes(role) || roles?.includes("OWNER");
    }

    getStatus(){
        return this.init().get("/core/interventionStatus").then(d=>d.data)
    }


    updateClient(d) {
        return this.init().put(`/core/client/${d.id}`,d).then(d=>d.data)
    }


    setPaid(id) {
        alert(text.FUNCTION_SOON_AVAILABLE)
    }

    getOperation(id) {
        return this.init().get(`/core/operation/${id}`).then(d=>d.data)
    }

    getInterventionToPlann(team, begin, end) {
        return this.init().get(`/core/intervention/planning?teamId=${team}&begin=${begin}&end=${end}`).then(d=>d.data)
    }
    getInterventionOfOtherTeam(team, begin, end) {
        return this.init().get(`/core/intervention/otherPlanning?teamId=${team}&begin=${begin}${end ? "&end="+end : ""}`).then(d=>d.data)
    }

    getPlanningEvent(team, begin, end) {
        return this.init().get(`/core/event/planning?teamId=${team}&start=${begin}&end=${end}`).then(d=>d.data)
    }

    moveOperation(interventionId,operationId,interventiondestination){
        return this.init().put(`/core/intervention/${interventionId}/operation/${operationId}?interventionTo=${interventiondestination}`).then(d=>d.data)
    }

    subscribe(companyuser){
        return this.init().post(`/auth/registration`,companyuser).then(d=>d.data)
    }
    updateCompany(company){
        return this.init().put(`/core/company/`+company.id,company).then(d=>d.data)
    }
    updateLogoCompany(id, logo){
        return this.init().put(`/core/company/${id}/logo`,logo).then(d=>d.data)
    }
    sendContact(contact){
          return this.init().put(`/core/contact/new`,contact).then(d=>d.data)
    }
    processContact(id, value){
          return this.init().post(`/core/contact/${id}/${value}`).then(d=>d.data)
    }
    getContact(processed){
          return this.init().get(`/core/contact/${processed}`).then(d=>d.data)
    }

    subscribeClient(companyuser){
        return this.init().post(`/auth/clientRegistration`,companyuser).then(d=>d.data)
    }

    getSiteOperation(id) {
        return this.init().get(`/core/site/${id}/operation`).then(d=>d.data)
    }

    getInterventionOperation(id){
        return this.init().get(`/core/intervention/${id}/operation`).then(d=>d.data)
    }

    getTaken(id){
        return this.init().get(`/core/supplyElement/taken/${id}`).then(d=>d.data)
    }

    setMontant(id,montant){
        return this.init().put(`/core/devis/${id}/montant?montant=${montant}`).then(d=>d.data)

    }

    setAcompte(id, acompte) {
        return this.init().put(`/core/devis/${id}/acompte?acompte=${acompte}`).then(d=>d.data)
    }

    createSector(sector) {
        return this.init().post(`/core/secteur/`,sector).then(d=>d.data)

    }

    updateSectorName(sectorId, name) {
        return this.init().put(`/core/secteur/${sectorId}/${name}`).then(d=>d.data)

    }
    updateSectorVisibility(sectorId, isHidden) {
        return this.init().put(`/core/secteur/${sectorId}/hidden/${isHidden}`).then(d=>d.data)

    }

    getPartition(id) {
        return this.init().get(`/core/intervention/${id}/partition`).then(d=>d.data)

    }

    generateCsv(filters, columns) {
        return this.init().put("/core/intervention/filter/csv",{},{params:filters})
            .then(d=> {
                let data = new Blob([d.data],{type:"octet/stream"});
                var url  = window.URL.createObjectURL(data);
                let ahref = document.createElement("a");
                ahref.setAttribute("href",url);
                ahref.setAttribute("download","result.csv")
                ahref.click()
        })
    }

    createUpdateEvent(event) {
        return this.init().post("/core/event",event).then(d=>d.data)
    }

    deleteEvent(event) {
        return this.init().delete("/core/event",{data:event}).then(d=>d.data)
    }

    getEvent(eventId) {
        return this.init().get(`/core/event/${eventId}`).then(d=>d.data)
    }

    setEstimate(id, estimate) {
        return this.init().put(`/core/intervention/${id}/estimate?estimate=${estimate}`).then(d=>d.data)
    }

    routeTiming(route) {
        return this.init().post(`/core/distance/`,route).then(d=>d.data)
    }

    getClock(start, end, team) {
        return this.init().get(`/core/couchbase/clock?start=${start}&end=${end}&id=${team}`).then(d=>d.data)
    }

    getMyUnderTypes() {
        return this.init().get("/core/couchbase/listMyUnderType").then(resp => resp.data)
    }

    getRiskLevels() {
        return this.init().get("/core/risk/level").then(resp => resp.data)
    }

    setCompanyDefaultRiskLevel(emergency, level) {
        return this.init().put(`/core/company/level?level=${level}&emergency=${emergency}`).then(resp => resp.data)
    }
    setCompanyDefaultStyle(style) {
        return this.init().put(`/core/company/style?frontStyle=${style}`).then(resp => resp.data)
    }

    getCompany() {
        return this.init().get("/core/company/myCompany").then(resp => resp.data)
    }

    amISuperAdmin(){
      return this.init().get("/auth/superAdmin").then(resp => resp.data)
    }
    setEmergencyMode(emergencyMode){
        return this.init().put(`/core/company/emergencyMode?value=${emergencyMode}`).then(resp => resp.data)
    }

    updateDevisSite(siteId, devisId){
        return this.init().put(`/core/site/${siteId}/devis?devisId=${devisId}`).then(resp => resp.data)
    }

    finishLastBillIntervention(id){
        return this.init().post(`/core/intervention/${id}/endLastBill`).then(resp => resp.data)
    }

    updateEmergencyEventStatus(id, status){
        return this.init().put(`/core/intervention/${id}/updateEmergencyEventStatus?status=${status}`).then(resp => resp.data)
    }
    deleteEmergencyEventStatus(id, status){
        return this.init().delete(`/core/intervention/${id}/deleteEmergencyEventStatus`).then(resp => resp.data)
    }

    getNotificationsNumber(){
        return this.init().get(`/notification-server/v1/notification/number`).then(resp => resp?.data)
    }
    getNotifications(){
        return this.init().get(`/notification-server/v1/notification`).then(resp => resp?.data)
    }
    readNotification(id){
        return this.init().put(`/notification-server/v1/notification/${id}/close`).then(resp => resp?.data)
    }
    closeNotif(id){
        return this.init().put(`/notification-server/notification/${id}/close`).then(resp => resp?.data)
    }

    getAllChats(){
        return this.init().get(`/notification-server/chat`).then(resp => resp.data)
    }
    getMessageFromChat(id){
        return this.init().get(`/notification-server/chat/byId/${id}/messages`).then(resp => resp.data)
    }
    getChatByParticipants(username){
        return this.init().get(`/notification-server/chat/byParticipant/${username}`).then(resp => resp.data)
    }

    sendChatMessage(chatId, receiver, msg){
        return this.init().post(`/notification-server/v1/message/${chatId}?receiver=${receiver}&msg=${msg}`).then(resp => resp.data)
    }
    updateUnReadMessage(chatId){
        return this.init().post(`/notification-server/v1/message/${chatId}/seen`).then(resp => resp.data)
    }


    newCatalogFamily(catalogFamily) {
        return this.init().post(`/core/couchbase/catalog/newFamily`, catalogFamily).then(r => r.data)
    }
    getFamilyProduct(id) {
        return this.init().get(`/core/couchbase/catalog/family/${id}`).then(r => r.data)
    }
    getCatalogFamilies() {
        return this.init().get(`/core/couchbase/catalog/families`).then(r => r.data)
    }


    newCatalogPreciserFamily(catalogFamily) {
        return this.init().post(`/core/couchbase/catalog/newPreciserFamily`, catalogFamily).then(r => r.data)
    }
    getCatalogPreciserFamilies() {
        return this.init().get(`/core/couchbase/catalog/preciserFamilies`).then(r => r.data)
    }

    getProductTypes() {
        return this.init().get(`/core/couchbase/catalog/productType`).then(r => r.data)
    }
    getProductTypesByPreciserFamily(preciserFamily) {
        return this.init().get(`/core/couchbase/catalog/productType?preciserFamily=${preciserFamily}`).then(r => r.data)
    }
    newProductType(productType) {
        return this.init().post(`/core/couchbase/catalog/newProductType`, productType).then(r => r.data)
    }

    newProduct(product){
        return this.init().post(`/core/couchbase/catalog/newProduct`, product).then(r => r.data)
    }
    getProducts() {
        return this.init().get(`/core/couchbase/catalog/products`).then(r => r.data)
    }
    addProductVariantImg(productId, variant, img) {
        return this.init().put(`/core/couchbase/catalog/productImg/${productId}/${variant}`, img).then(r => r.data)
    }
    getProductListByProductTypeName(productTypeName){
        return this.init().get(`/core/couchbase/catalog/products?productTypeName=${productTypeName}`).then(r => r.data)
    }


    createQuotation(quotation, interventionId, devisId) {
        // return this.init().post(`/core/quotation${interventionId ? `?interventionId=${interventionId}` : ""}`, quotation).then(r => r.data)
        return this.init().post(`/core/quotation?interventionId=${interventionId??""}&devisId=${devisId??""}`, quotation).then(r => r.data)
    }
    updateQuotation(quotation, interventionId, devisId, emailType) {
        return this.init().post(`/core/quotation/update?interventionId=${interventionId}&devisId=${devisId}&emailType=${emailType}`, quotation).then(r => r.data)
    }

    getFilterQuotations(params) {
        return this.init().get("/core/quotation/filter", {params: params ? params : {}})
            .then(respose => {
                return {
                    quotations: respose.data.content.map(e => this.normalizeQuotation(e)),
                    count: respose.data.totalElements
                }
            })
    }
    getQuotation(id) {
        return this.init().get(`/core/quotation/${id}`).then(r => r.data)
    }

    getQuotationStatus(){
        return this.init().get("/core/quotation/status").then(d=>d.data)
    }
    updateQuotationStatus(id, status){
        return this.init().put(`/core/quotation/status/${id}/${status}`).then(d=>d.data)
    }

    sendQuotation(quotation, interventionId, devisId, emailType) {
        return this.init().post(`/core/quotation/send?interventionId=${interventionId}&devisId=${devisId}&emailType=${emailType}`, quotation).then(r => r.data)
    }

    updateSiteClientMail(siteId, mail, target) {
        return this.init().put(`/core/${target}/${siteId}/mail?mail=${mail}`).then(resp => resp.data);
    }

    updateBillEntity(quotationId, clientId) {
        return this.init().put(`/core/quotation/${quotationId}/billEntity?clientId=${clientId}`).then(resp => resp.data);
    }

    updateQuotationComment(id, comment) {
        return this.init().put(`/core/quotation/${id}/comment?comment=${comment}`).then(resp => resp.data);
    }
    updateQuotationName(id, name) {
        return this.init().put(`/core/quotation/${id}/name?name=${name}`).then(resp => resp.data);
    }
    updateQuotationHourlyRate(id, hourlyRate) {
        return this.init().put(`/core/quotation/${id}/hourlyRate?hourlyRate=${hourlyRate}`).then(resp => resp.data);
    }

    getOdooQuotationPdf(type, odooId) {
        return this.init().get(`/core/${type}/${odooId}/pdf`).then(resp => resp.data);
    }
    previewQuotationPdf(odooId) {
        return this.init().get(`/core/quotation/${odooId}/pdfPreview`).then(resp => resp.data);
    }

    signQuotation(id) {
        return this.init().post(`/core/quotation/${id}/signQuotation`).then(resp => resp.data);
    }

    createBill(ids, interventionType, tax){
        return this.init().post(`/core/bill?interventionType=${interventionType}&tax=${tax}`, ids).then(resp => resp.data);
    }

    createBillFromQuotation(quotationId, interventionId, bpi, billEntityId){
        return this.init().post(`/core/bill/fromQuotation/${quotationId}?interventionId=${interventionId}&bpi=${bpi}&billEntity=${billEntityId}`).then(resp => resp.data);
    }

    getInterventionQuotation(id) {
        return this.init().get(`/core/intervention/${id}/quotations`).then(resp => resp.data);
    }

    getPoiTypes(layerType) {
        return this.init().get(`/core/couchbase/job/poiType?layerType=${layerType}`).then(resp => resp.data);

    }

    addPointToQuotationLine(quotationLineId, point) {
        return this.init().post(`/core/quotation/quotationLine/${quotationLineId}/addPoint`, point).then(resp => resp.data);
    }


    getFuturesSupplierInterventions(siteId) {
        return this.init().get(`/core/intervention/futureSupplierIntervention?siteId=${siteId}`).then(resp => resp.data.map(e => this.normalize(e)));
    }
    joinSupplyForSupplier(interventionId, supplyId) {
        return this.init().put(`/core/intervention/${interventionId}/joinSupply/${supplyId}`).then(resp => resp.data)
    }
    getInterventionLinkToSupplierIntervention(begin) {
        return this.init().get(`/core/intervention/linkInterventionAndSupplier?begin=${begin}`).then(resp => resp.data)
    }


    getOriginIntervention(devisId) {
        return this.init().get(`/core/intervention/getOriginIntervention/${devisId}`).then(resp => resp.data)
    }

    updateAvailabilityDate(id, date) {
        return this.init().put(`/core/intervention/${id}/availabilityDate?date=${date}`).then(resp => resp.data)
    }
}
